import React, { useState, useEffect } from 'react';
import '../css/styles.css';
import '../css/productos.css';
import Logo from '../assets/img/efit.avif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faCartShopping, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import Cart from '../Pages/Cart';
import { useCart } from './Cart/CartContext';



const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false);
    const [categorias, setCategorias] = useState([]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const { cart, cartCategoryData } = useCart();
    const uniqueProducts = new Set(cart.map(product => product.id));
    const cartQuantity = uniqueProducts.size;

    const handleCartOpen = () => {
        setIsCartOpen(prevIsCartOpen => !prevIsCartOpen);
    };
    useEffect(() => {
        setCategorias(cartCategoryData);
    }, [cartCategoryData]);   

    return (
        <header className='headerNav'>
            <div className='contactoHeader'>
                <p className='contactosHeader'>
                    <FontAwesomeIcon icon={faPhone} /> +54 3572 44-8243
                </p>
                <p className='contactosHeader'>
                    <FontAwesomeIcon icon={faEnvelope} /> efitequipamientos@gmail.com
                </p>
                <p className='contactosHeader' href="https://www.instagram.com/efit.equipamiento/" target='blank'>
                    <FontAwesomeIcon className='ig' icon={faInstagram} />efit.equipamientos
                </p>
            </div>
            <div className="row contNav">
                <Link className='col-1 contLogo' to='/'>
                    <img className="logo" src={Logo} alt="" />
                </Link>
                <div className='col-2 potenciaNav'>
                    Potenciá tu gimnasio con <br /> lo mejor en equipamiento
                </div>
                <div className="col-md-6 col-8 contHamburgesa">
                    <input type="checkbox" name="" id="toggle" />
                    <label htmlFor="toggle"><FontAwesomeIcon icon={faBars} className='hamburguesa' /></label>
                    <div className="menu">
                        <ul className='contenedorMenuNav'>
                            <li className='contenedorNavbar'>
                                <Link className="btnNavbar" to="/">Home</Link>
                            </li>
                            <div className="dropdown">
                                <button className="dropbtn" onClick={toggleDropdown}>
                                    Productos<FontAwesomeIcon className='dropIcon' icon={faCaretDown} />
                                </button>
                                {isOpen && (
                                    <div className="dropdown-content">
                                        <Link to="/Catalogo">Todos los Productos</Link>
                                        {categorias.map(categoria => (
                                            <li>
                                                <Link to={`/Subcategorias`} key={categoria.id}>
                                                    {categoria.nombre}
                                                </Link>
                                            </li>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <li className='contenedorNavbar'>
                                <Link className="btnNavbar" to="/Ofertas">Ofertas</Link>
                            </li>
                            <li className='contenedorNavbar'>
                                <Link className="btnNavbar" to="/Nosotros">Nosotros</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-2 col-md-1 cart-icon" onClick={handleCartOpen}>
                    {cartQuantity > 0 && <span className="cart-count">{cartQuantity}</span>}
                    <FontAwesomeIcon icon={faCartShopping} />
                </div>
            </div>

            <Cart
                isCartOpen={isCartOpen}
                handleCartOpen={handleCartOpen}
            />
        </header>
    );
};

export default Navbar;

