import React, { useState, useCallback, useEffect, useRef } from "react";
import Navbar from '../components/Navbar';
import ProductList from '../components/Products/ProductList';
import SearchBar from "../components/Search/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Navbarr from "../components/NavCatego/NavBar1";
import Offcanvas from "react-bootstrap/Offcanvas";
import Footer from "../components/Footer";
import CategoryButtons from "../components/NavCatego/CategoryButtons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Navbarr1 from '../components/NavCatego/NavBar1';
import { useCart } from "../components/Cart/CartContext";

const Cart = () => {

    const [selectedType, setSelectedType] = useState('todos');
    const [selectedFilter, setSelectedFilter] = useState('todos');
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [categoria, setCategoria] = useState([]);
    const [data, setData] = useState([]);

    const { cartProductData, cartCategoryData } = useCart();

    useEffect(() => {
        setData(cartProductData);
        console.log(cartProductData);
    }, [cartProductData])
    
    useEffect(() => {
        setCategoria(cartCategoryData);
        console.log(cartCategoryData);
    }, [cartCategoryData])

    useEffect(() => {
        if (data && data.length > 0) {
            setDataLoaded(true);
        }
    }, [data]);

    const searchProducts = useCallback((term) => {
        const results = term
            ? data.filter((product) =>
                product.name.toLowerCase().includes(term.toLowerCase())
            )
            : data;
        setSearchResults(results);
    }, [data]);

    useEffect(() => {
        if (dataLoaded) {
            searchProducts(searchTerm);
        }
    }, [dataLoaded, searchTerm, searchProducts]);

    useEffect(() => {
        if (dataLoaded) {
            searchProducts("");
        }
    }, [dataLoaded, searchProducts]);

    const handleSearchTermChange = useCallback((event) => {
        const term = event.target.value;
        setSearchTerm(term);
    })
;

    const toggleMenu = useCallback(() => {
        setMenuOpen((menuOpen) => !menuOpen);
    });

    const handleTypeChange = useCallback((type) => {
        setSelectedType(type);
        if (menuOpen) {
            setMenuOpen(false);
        }
    });


useEffect(() => {
    const selectedSubcategoriaNombre = localStorage.getItem('selectedSubcategoriaNombre');
    const selectedCategoriaNombre = localStorage.getItem('selectedCategoriaNombre');

    if (selectedSubcategoriaNombre && selectedCategoriaNombre !== '') {
        handleTypeChange(`${selectedCategoriaNombre}-${selectedSubcategoriaNombre}`);
        setTimeout(() => {
            localStorage.removeItem('selectedSubcategoriaNombre');
            localStorage.removeItem('selectedCategoriaNombre');
        }, 5000);
    } else if(selectedSubcategoriaNombre && selectedCategoriaNombre === '') {
        handleTypeChange('todos');
        setSelectedFilter('todos');
        handleOptionClickFl('borrar-filtros');
        localStorage.removeItem('selectedSubcategoriaNombre');
        localStorage.removeItem('selectedCategoriaNombre');
    }
}, [selectedFilter, selectedType]);

    

    const menuRef = useRef(null);

    const handleOptionClickFl = () => {
        setSelectedFilter("todos");
    };

    const handleBorrarSearch = () => {
        setSearchTerm("");
    };
    return (
        <div className="contMain1">
            <Navbar />
            <Offcanvas show={menuOpen} onHide={toggleMenu} placement="end">
                <div className={`row cart-overlay`}>
                    <div className={`col-12 cart-container`}>
                        <div ref={menuRef} className="contCart">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "right",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "30px",
                                        height: "20px",
                                        position: "relative",
                                        cursor: "pointer",
                                        top: "5px",
                                    }}
                                    type="button"
                                    onClick={toggleMenu}
                                >
                                    <FontAwesomeIcon icon={faClose} className="iconX" />
                                </div>
                            </div>
                            <div>
                                <CategoryButtons
                                    categories={categoria}
                                    selectedType={selectedType}
                                    handleTypeChange={handleTypeChange}
                                    handleFilterChange={setSelectedFilter}
                                    setMenuOpen={setMenuOpen}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas>
            <div
                className="contenedorSearchBar"
                style={{ paddingRight: "0%", marginRight: "0%", width: "100%" }}
            >
                <div>
                    <div
                        className="col-12 col-sm-12 col-lg-9 col-xl-12 contSearchBar"
                        style={{
                            paddingRight: "0%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <button
                            onClick={() => {
                                handleTypeChange("todos");
                                handleOptionClickFl("borrar-filtros");
                                handleBorrarSearch("");
                            }}
                            className="txtBorrarFil"
                        >
                            <h6>Borrar Filtros</h6>
                        </button>
                        <SearchBar
                            searchTerm={searchTerm}
                            handleSearchTermChange={handleSearchTermChange}
                        />
                        <div className="contFilter">
                            <Navbarr1
                                menuOpen={menuOpen}
                                toggleMenu={toggleMenu}
                                selectedType={selectedType}
                                handleTypeChange={handleTypeChange}
                                handleFilterChange={setSelectedFilter}
                            />
                        </div>
                    </div>
                    <h4 className='tresYSeis'>
                        3 y 6 Cuotas sin Interés con Macro
                    </h4>
                </div>
            </div>
            <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
            >
                <div className="col-md-2 col-3 contBtnsFilter">
                    <div>
                        <Navbarr className="filtroPantallaGrande"
                            menuOpen={menuOpen}
                            toggleMenu={toggleMenu}
                            selectedType={selectedType}
                            handleTypeChange={handleTypeChange}
                            handleFilterChange={setSelectedFilter}
                        />
                        <button
                            onClick={() => {
                                handleTypeChange("todos");
                                handleOptionClickFl("borrar-filtros");
                                handleBorrarSearch("");
                            }}
                            className="txtBorrarFilDos"
                        >
                            <span>Borrar Filtros</span>
                        </button>
                    </div>
                </div>
                <div className="col-md-10 col-9 containerProductsPrincipal">
                    <ProductList
                        searchResults={searchResults}
                        selectedType={selectedType}
                        selectedFilter={selectedFilter}
                    />
                </div>
            </div>
            <section className='loguitoWSP'>
                <a className='iconoWspMain' href="https://api.whatsapp.com/send/?phone=5493572448243&text=Hola!+Te+quiero+hacer+una+consulta+sobre+tu+servicio...&type=phone_number&app_absent=0" target='blank'>
                    <FontAwesomeIcon className='wspLink' icon={faWhatsapp} />
                </a>
            </section>
            <Footer />
        </div >
    );

};

export default Cart;
