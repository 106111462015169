import React from 'react';
import { useCart } from '../components/Cart/CartContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Offcanvas } from 'react-bootstrap';
import { faClose, faLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';


const Cart = ({
    handleCartOpen,
    isCartOpen
}) => {
    const { cart, removeFromCart } = useCart();


    return (
        <Offcanvas show={isCartOpen} onHide={handleCartOpen} placement="end">
            {
                <div className={`row cart-overlay`}>
                    <div className={`col-12 cart-container`}>
                        <div className="contCart">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "right",
                                    position: "relative",
                                    top: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        width: "30px",
                                        height: "20px",
                                        position: "relative",
                                        right: "15px",
                                        cursor: "pointer",
                                    }}
                                    type="button"
                                    onClick={handleCartOpen}
                                >
                                    <FontAwesomeIcon icon={faClose} className="iconX" />
                                </div>
                            </div>
                            <div
                                style={{
                                    paddingBottom: "15px",
                                    paddingTop: "3px",
                                    position: "relative",
                                    left: "8px",
                                    borderBottom: "1px solid #000",
                                    width: "96%",
                                }}
                            >
                                <span
                                    style={{
                                        color: "#000",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                    }}
                                >
                                    Mi carrito
                                </span>
                            </div>
                            {cart.length === 0 ? (
                                <div
                                    className="row classFlexCenterGen"
                                    style={{ paddingTop: "25px" }}
                                >
                                    <div className="col-12 classFlexCenterGen">
                                        <span className="txtCartVa">
                                            El carrito de compras está vacio.
                                        </span>
                                    </div>
                                    <div className="col-12 classFlexCenterGen">
                                        <a
                                            type="button"
                                            onClick={handleCartOpen}
                                            style={{ color: "#000", textDecoration: "none" }}
                                            className="txtVolTien"
                                        >
                                            <FontAwesomeIcon
                                                icon={faLeftLong}
                                                style={{ paddingRight: "5px" }}
                                            />
                                            Volver a la Tienda
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="contItemsCart">
                                        <ul className='ulCart'>
                                            {cart.map((product) => (
                                                <li
                                                    key={product.id}
                                                    style={{
                                                        marginTop: "15px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className="row contItemsCarrito">
                                                        <div className="col-md-1 col-2" style={{ paddingLeft: "2px", paddingRight: 0 }}>
                                                            <img src={product.images[0]} className="imgCarrito"/*  alt={product.nombre} */ />
                                                        </div>
                                                        <div className="col-md-11 col-10" >
                                                            <div style={{ paddingLeft: "10px" }}>
                                                                <p className="txtCarritoFrmEnv">
                                                                    x{product.cantidad} {product.name}
                                                                </p>
                                                                <p className="txtCarritoFrmEnvPrice">
                                                                    {new Intl.NumberFormat("es-AR", {
                                                                        style: "currency",
                                                                        currency: "ARS",
                                                                    }).format(product.precio_uni)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-11 col-12 contBtnElim">
                                                            <button
                                                                onClick={() => removeFromCart(product.id)}
                                                                className="btnCartVac"
                                                                style={{
                                                                    marginLeft: "10px",
                                                                }}
                                                            >
                                                                Borrar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        <button className='btnIniciarCompra'>
                                            <Link to='/FormularioDeEnvio' className='txtIniciarCompra'>Iniciar Compra</Link>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
        </Offcanvas>
    );
};

export default Cart;
