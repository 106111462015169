import React, { useState, useEffect } from "react";
import { useCart } from '../components/Cart/CartContext';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Formulario = () => {
    const [formData, setFormData] = useState({
        nombre: "",
        apellido: "",
        dni: "",
        telefono: "",
        email: "",
        codigoPostal: ""
    });

    const { cart } = useCart();

    const calculateTotal = () => {
        let numero = cart.reduce(
            (total, item) =>
                total + Number(item.precio_uni).toFixed(2) * item.cantidad,
            0
        );
        let formater = new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
        }).format(numero);
        return formater;
    };

    const cargarProductosMensaje = () => {
        console.log(cart)
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validar si todos los campos están completos
        const formFields = ['nombre', 'apellido', 'dni', 'telefono', 'codigoPostal'];
        const isFormValid = formFields.every(field => formData[field].trim() !== '');
        const itemsInfo = cart.map(item => `${item.cantidad} ${item.name}`).join(', ');
        if (!isFormValid) {
            alert('Por favor, completa todos los campos.');
        } else {

            const whatsappMessage = `Hola! Soy ${formData.nombre} ${formData.apellido}, mi numero de telefono es ${formData.telefono} y mi correo electronico es ${formData.email}. Mi Código Postal es ${formData.codigoPostal}. Te quería hacer una consulta sobre la compra de ${itemsInfo}`;
            const whatsappLink = `https://api.whatsapp.com/send/?phone=5493572500003&text=${encodeURIComponent(whatsappMessage)}`;
            window.open(whatsappLink, '_blank');
        }
    };

    const handleProduct = (e)=>{
        e.preventDefault()
        window.location.href = "/Catalogo"
    }

    useEffect(() => {
        cargarProductosMensaje();
    }, [])

    return (
        <div className="contPrincipalForm">
            <div className='totalContainerForm'>
                <div className='divContForm row'>
                    <div className="col-md-8 col-12 contFormu">
                        <div className="headerForm">
                            <img src={require('../assets/img/efit.avif')}  />
                        </div>
                        <form onSubmit={handleSubmit} className='contInputsForm'>
                            <div
                                className="row cardEntrega checked"
                                style={{ paddingLeft: "0%", marginRight: "0%", paddingBottom: "25px", cursor: "default", background: "#fff" }}
                            >
                                <div
                                    className="col-12 formEnv"
                                    style={{ justifyContent: "center" }}
                                >
                                    <p
                                        style={{
                                            marginTop: "15px",
                                            fontSize: ".875rem",
                                            fontWeight: "700",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        Datos del destinatario
                                    </p>
                                </div>
                                <div
                                    className="col-md-6 col-6"
                                    style={{ paddingRight: "0%" }}
                                >

                                    <label>
                                        Nombre:
                                        <br />
                                        <input
                                            required
                                            id="txtNombre"
                                            type="text"
                                            name="nombre"
                                            className="form-input inputCentrado"
                                            placeholder="Juan"
                                            value={formData.nombre}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div
                                    className="col-md-6 col-6"
                                    style={{ paddingRight: "0%" }}
                                >
                                    <label>
                                        Apellido:
                                        <br />
                                        <input
                                            required
                                            id="txtNombre"
                                            type="text"
                                            className="form-input inputCentrado"
                                            placeholder="Perez"
                                            name="apellido"
                                            value={formData.apellido}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div
                                    className="col-md-6 col-6"
                                    style={{ paddingRight: "0%" }}
                                >

                                    <label>
                                        DNI:
                                        <br />
                                        <input
                                            required
                                            id="txtNombre"
                                            className="form-input inputCentrado"
                                            placeholder="99999999"
                                            type="text"
                                            name="dni"
                                            value={formData.dni}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div
                                    className="col-md-6 col-6"
                                    style={{ paddingRight: "0%" }}
                                >

                                    <label>
                                        Teléfono:
                                        <br />
                                        <input
                                            required
                                            id="txtNombre"
                                            className="form-input inputCentrado"
                                            placeholder="+54 3572999999"
                                            type="text"
                                            name="telefono"
                                            value={formData.telefono}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div
                                    className="col-md-6 col-6"
                                    style={{ paddingRight: "0%" }}
                                >

                                    <label>
                                        Email:
                                        <br />
                                        <input
                                            id="txtNombre"
                                            className="form-input inputCentrado"
                                            placeholder="ejemplo@ejemplo.com"
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div
                                    className="col-md-6 col-6"
                                    style={{ paddingRight: "0%" }}
                                >

                                    <label>
                                        Código Postal:
                                        <br />
                                        <input
                                            required
                                            id="txtNombre"
                                            className="form-input inputCentrado"
                                            placeholder="5986"
                                            type="text"
                                            name="codigoPostal"
                                            value={formData.codigoPostal}
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div
                                    className="col-6"
                                    style={{ paddingRight: "0%" }}
                                >

                                    <button className='justify-content-center sumbit contBtnVolver' type='submit'>Enviar</button>
                                </div>
                                <div
                                    className="col-6 contBtnVolver"
                                    style={{ paddingRight: "0%" }}
                                >
                                    <button className='justify-content-center btnVolver' type='button' onClick={handleProduct}> <FontAwesomeIcon  style={{paddingRight:"5px"}} icon={faArrowLeft}/>Volver a Catalogo</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="contCartEnv col-md-3 col-12">
                        <div className="contCartEnvDos">
                            <p className="txtTitCartEnv">Tu Carrito</p>
                            {cart.map((item) => (
                                <li
                                    key={item.id}
                                    style={{
                                        marginTop: "15px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={item.images[0]}
                                        className="imgCarrito" /*  alt={item.nombre} */
                                    />
                                    <div style={{ paddingLeft: "10px" }}>
                                        <p className="txtCarritoFrmEnv">
                                            x{item.cantidad} {item.name}
                                        </p>
                                        <p className="txtCarritoFrmEnvPrice">
                                            {new Intl.NumberFormat("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                            }).format(item.precio_uni)}
                                        </p>
                                    </div>
                                </li>
                            ))}
                            <hr style={{ margin: "50px 0 20px 0" }} />
                            <div className="contTotalAPagar">
                                <p className="txtTotalAPagar">Total</p>
                                <p className="txtTotalAPagar">{calculateTotal()}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Formulario