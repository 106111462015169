import React from "react";
import "../../css/productos.css"
import { Link } from "react-router-dom";



const Product = ({ product }) => {
  let precio = Number(product.precio_uni).toFixed(2);

  return (
    <div className="container-fluid col-md-4 col-12 cardProduct">
      <Link to={`/Catalogo/${product.id}`} className="col-3 linkProductDetail">
        <div className="card">
          <div className="imageBox">
            {product.images.length > 1 ? (
              <img style={{
                width:"100%",
                height:"220px"
              }} src={product.images[1]} alt={product.name} />
            ) : (
              <img src={product.images} alt={product.name} />
            )}
            <div className="content">
              <h2>
                {product.name}
              </h2>
              <p>
                Precio: USD {precio} + IVA
              </p>
              <button className="verMasBtn">
                Ver Más
              </button>
            </div>
          </div>
        </div>
      </Link>
    </div>

  );
};

export default Product;
