import React from "react";
import Product from "./Products";

const ProductList = ({
  searchResults,
  selectedType,
  selectedFilter,
}) => {

const filteredProducts = searchResults.filter(product => {
  // Filtro por categoría
  if (selectedFilter && selectedFilter !== 'todos' && product.categoria !== selectedFilter) {
    return false;
  }

    // Verifica si selectedType contiene un guion
    if (selectedType !== 'todos') {
      const typeParts = selectedType.split('-'); // Divide el texto usando el guion como separador
      if (typeParts.length === 2) {
        const selectedCategory = typeParts[0].trim(); // La primera parte es la categoría
        const selectedBrand = typeParts[1].trim(); // La segunda parte es la marca
        if (selectedCategory !== 'todos' && product.categoria !== selectedCategory) {
          return false;
        }
        if (selectedBrand !== 'todos' && product.categoMarca !== selectedBrand) {
          return false;
        }
      } else {
        // Si no hay guion, realiza la comparación normal
        if (selectedType !== 'todos' && product.categoria !== selectedType) {
          return false;
        }
      }
    }

    if (product.id_estado === "Inactivo" || Number(product.stk_disp) <= 0) {
      return false;
    }

    return true;
  })

  const compareProducts = (a, b) => {
    // Verificar si los objetos tienen la propiedad 'nombre'
    if (a.name !== undefined && b.name !== undefined) {
      // Si los names son números, los ordena de forma ascendente
      if (!isNaN(a.name) && !isNaN(b.name)) {
        return parseInt(a.name) - parseInt(b.name);
      }
      // Si ambos names son letras, los ordena alfabéticamente
      else if (isNaN(a.name) && isNaN(b.name)) {
        return a.name.localeCompare(b.name);
      }
      // Coloca los números antes que las letras
      else if (!isNaN(a.name) && isNaN(b.name)) {
        return -1;
      }
      // Coloca las letras después de los números
      else {
        return 1;
      }
    } else {
      // Si alguno de los objetos no tiene la propiedad 'nombre', devuelve 0
      return 0;
    }
  };


  // Ordenar los productos
  const sortedProducts = filteredProducts.sort(compareProducts);

  const selectedTypeDisplay = selectedType === 'todos' ? 'Todos los Productos' : selectedType;

  return (
    <div className="row container-fluid contProductMayor" style={{ marginRight: "0%" }}>
      <h2 className="tituloCategoria">
        {selectedTypeDisplay}
      </h2>
      <hr></hr>
      {
        sortedProducts.map((product, index) => (
          <Product
            key={`${product.nombre}-${index}`}
            product={product}
          />
        ))
      }
    </div>
  );
}

export default ProductList;
