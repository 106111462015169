import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useCart } from '../components/Cart/CartContext';

const ProductDetail = () => {
  const [product, setProduct] = useState(null);
  const [data, setData] = useState([]);
  const { products } = useParams();

  
  const { cartProductData } = useCart();

  useEffect(() => {
    setData(cartProductData);
}, [cartProductData])


  useEffect(() => {
    const fetchData = async () => {
      const foundProduct = data.find((product) => product.id === products);
      setProduct(foundProduct);
    };

    fetchData();
  }, [products, data]);

  const ImageChanger = () => {
    const [currentImage, setCurrentImage] = useState(0);

    const handleThumbnailClick = (index) => {
      setCurrentImage(index);
    };

    return (
      <div>
        <div className='contImgsDetail'>
          <img className='imgPrincipalDetalle' src={product?.images[currentImage]} alt={`Imagen ${currentImage + 1}`} style={{ width: "90%", height: "85%" }} />

          <div className='contImg'>
            {product.images.map((image, index) => (
              <img className='imgDetail' key={index} src={image} style={{ width: '50px' }} alt={`Product ${index}`} onClick={() => handleThumbnailClick(index)} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const [number, setNumber] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [maxQuantity, setMaxQuantity] = useState(1);

  const restar = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  }

  const sumar = () => {
    if (number < maxQuantity) {
      setNumber(number + 1);
    }
  }

  useEffect(() => {
    setQuantity(number)
  }, [number])

  useEffect(() => {
    if (product) {
      setMaxQuantity(product.stk_disp);
    }
  }, [product]);


  const { addToCart } = useCart();

  const handleAddToCart = (item, cantidad) => {
    addToCart({ ...item, cantidad }); // Añade la cantidad al producto antes de agregarlo al carrito
  };

  if (!product) {
    return (
      <div>
        Producto No Encontrado
      </div>
    );
  } else {
    return (
      <div className='productoDetalle'>
        <Navbar />
        <div className='contProdDetail'>
          <div>
            <ImageChanger />
          </div>
          <div className='contInfoDetalle'>
            <h2 className='infoName'>{product.name}</h2>
            <p className='infoSecundaria'>Precio: {new Intl.NumberFormat("es-AR", {
              style: "currency",
              currency: "USD",
            }).format(product.precio_uni)}</p>
            <p className='infoSecundaria'>Categoría: {product.categoria}</p>
            <p className='descripcionDetalle'>{product.descripcion}</p>
            <p className='infoStk'>Stock Disponible: {product.stk_disp}</p>

            <div className='contBtns'>
              <button onClick={restar} className='botonesSyR'>-</button>
              <p className='number'>{quantity}</p>
              <button onClick={sumar} className='botonesSyR'>+</button>
            </div>
            <button onClick={() => handleAddToCart(product, quantity)} className='añadeCarrito'>Añadir al Carrito</button>
          </div>
        </div>
      </div>
    );
  }
};

export default ProductDetail;