import { React, useState, useEffect, useMemo } from 'react'
import '../../css/styles.css'


const SliderClientes = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const imageWidth = 237;

    const images = useMemo(() =>[
        require('../../assets/img/hatsapp-image-2023-11-27-at-12-21-26.avif'),
        require('../../assets/img/sapp-image-2023-11-27-at-12-43-00-1.avif'),
        require('../../assets/img/atsapp-image-2023-11-27-at-11-51-31.avif'),
        require('../../assets/img/atsapp-image-2023-11-27-at-12-05-46.avif'),
        require('../../assets/img/atsapp-image-2023-11-27-at-12-08-43.avif'),
        require('../../assets/img/atsapp-image-2023-11-27-at-12-13-49.avif'),
        require('../../assets/img/atsapp-image-2023-11-27-at-12-17-32.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-28-at-12.08.40.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-28-at-12.12.47.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-28-at-12.16.49.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-28-at-12.21.52.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-28-at-12.22.40.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-29-at-09.24.11.avif'),
        require('../../assets/img/WhatsApp-Image-2023-11-29-at-09.31.04.avif'),
        require('../../assets/img/WhatsApp-Image-2024-02-29-at-7.06.33-PM.avif'),
        require('../../assets/img/WhatsApp-Image-2024-03-01-at-4.55.56-PM.avif'),
        require('../../assets/img/WhatsApp-Image-2024-02-29-at-7.20.00-PM.avif')
    ]
    , []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); // Cambia el intervalo según tus necesidades

        return () => clearInterval(intervalId);
    }, [images]);

    return (
        <div>
            <h1 className='textoClientes'>
                Clientes que nos avalan
            </h1>
            <div className='slider'>
                <div className='slideTrack' style={{ width: `${images.length * imageWidth}px`, transform: `translateX(-${currentIndex * imageWidth}px)` }}>
                    {images.map((image, index) => (
                        <div key={index} className='slide'>
                            <img src={image} alt="" />
                        </div>
                    ))}
                    {images.map((image, index) => (
                        <div key={index + images.length} className='slide'>
                            <img src={image} alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SliderClientes;