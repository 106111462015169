import React, { useEffect, useState } from 'react';
import SliderClientes from './Slider/SliderClientes';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useCart } from './Cart/CartContext';

const MainInicio = () => {
    const [randomProducts, setRandomProducts] = useState([]);
    const [products, setProducts] = useState([]);

    const { cartProductData } = useCart();

    useEffect(() => {
        if (cartProductData.length > 0) {
            const shuffledProducts = shuffle(cartProductData);
            const randomProducts = shuffledProducts.slice(0, 3);
            setRandomProducts(randomProducts);
        }
    }, [cartProductData]);

    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]
            ];
        }

        return array;
    };

    return (
        <div className='divInicio'>
            <main className='mainInicio'>
                <video className='videoMain' autoPlay controls loop>
                    <source src={require("../assets/efit.mp4")} type="video/mp4" />
                </video>
                <section className='sectionMain2'>
                    <SliderClientes />
                </section>

                <section className='sectionMain'>
                    <img className='imgMainCertf' src={require('../assets/img/certificaciones-e-fit.avif')} alt="" />
                </section>

                <section className='sectionMain1'>
                    {randomProducts.map((product, index) => (
                        <div key={index} className='contImgLinks' href="">
                            <Link to={`/Catalogo/${product.id}`}>
                                <div className='contImgLinks1'>
                                    <img className='imgLinks' src={product.images[1]} alt="--" />
                                </div>
                            </Link>
                            <ul className='listaInicio'>
                                <li>
                                    <Link to={`/Catalogo/${product.id}`} className='btnVerMasInicio'> Ver Detalle </Link>
                                </li>
                            </ul>
                        </div>
                    ))}
                </section>

                <section className='loguitoWSP'>
                    <a className='iconoWspMain' href="https://api.whatsapp.com/send/?phone=5493572448243&text=Hola!+Te+quiero+hacer+una+consulta+sobre+tu+servicio...&type=phone_number&app_absent=0" target='blank'>
                        <FontAwesomeIcon className='wspLink' icon={faWhatsapp} />
                    </a>
                </section>

            </main>
        </div>
    );
};

export default MainInicio;
