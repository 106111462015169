import React from 'react'
import '../css/styles.css'
import Logo from '../assets/img/efit.avif'




function Footer() {
    return (
        <div className='contenedorFooter'>
            <footer className='contFooter'>
                <picture className='contImgFooter'>
                    <img className='imgFooter' src={Logo} alt="" />
                </picture>
                <div className='contIconos'>
                </div>
            </footer>
        </div>
    )
}

export default Footer
