import React, { createContext, useContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import FirebaseDataFetcher from '../Llamada/FirebaseDataFetcher';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [cartProductData, setCartProductData] = useState([]);
  const [cartCategoryData, setCartCategoryData] = useState([]);
  const [cartSubCategoryData, setCartSubCategoryData] = useState([]);

  const categoriesData = FirebaseDataFetcher({ collectionName:'categorias' });
  const subCategoriesData = FirebaseDataFetcher({ collectionName:'categoMarca' });
  const productsData = FirebaseDataFetcher({ collectionName:'productos' });

  useEffect(() => {
    setCartCategoryData(categoriesData);
  }, [categoriesData]); 
  
  useEffect(() => {
    setCartSubCategoryData(subCategoriesData);
  }, [subCategoriesData]);

  useEffect(() => {
    setCartProductData(productsData);
  }, [productsData]);


  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product) => {
    const existingProductIndex = cart.findIndex((item) => item.id === product.id);
    const updatedCart = [...cart];

    if (existingProductIndex !== -1) {
      // Product already exists in the cart, update the quantity
      const newQuantity = updatedCart[existingProductIndex].cantidad + product.cantidad;

      if (newQuantity <= product.stk_disp) {
        updatedCart[existingProductIndex].cantidad = newQuantity;
        setCart(updatedCart);
      } else {
        // Si la nueva cantidad supera el stock disponible, mostrar alerta
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se puede agregar más cantidad que el stock disponible',
        });
      }
    } else {
      // Product is not in the cart, add it
      if (product.cantidad <= product.stk_disp) {
        setCart((prevCart) => [...prevCart, product]);
      } else {
        // Si la cantidad supera el stock disponible, mostrar alerta
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se puede agregar más cantidad que el stock disponible',
        });
      }
    }
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((product) => product.id !== productId));
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, cartProductData, cartCategoryData, cartSubCategoryData }}>
      {children}
    </CartContext.Provider>
  );
};
