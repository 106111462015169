import React, { useState, useEffect } from "react";
import { useCart } from "../Cart/CartContext";

const CategoryButtons = ({ selectedType, handleTypeChange, handleFilterChange }) => {
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubcategorias] = useState([]);
  
  const { cartSubCategoryData, cartCategoryData } = useCart();

  useEffect(() => {
    setCategorias(cartCategoryData);
}, [cartCategoryData]);  

useEffect(() => {
  setSubcategorias(cartSubCategoryData);
}, [cartSubCategoryData]);

  const handleSubCategoryClick = (selectedCategory, subCategoria) => {
    const selectedBrand = subCategoria.trim(); // Usar opcional chaining para evitar errores si subCategoria es undefined
    const combinedSelection = `${selectedCategory}-${selectedBrand}`;
    handleTypeChange(combinedSelection);
};


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "right",
        paddingRight: "0%",
        paddingTop: "0%",
      }}
    >
      <div className="store__categories contBotones">
        <ul
          style={{
            listStyle: "none",
            paddingLeft: "0",
            textAlign: "left",
            fontFamily: "Arial, sans-serif",
            width: "100%",
          }}
        >
          <div className="contCateg">
            <div className="filtradoUno">
              <h6 className="tituloCateg" style={{ fontWeight: "bold" }}>Categorías</h6>
              <hr />
              {categorias.map((item) => (
                <li
                  key={item.id}
                >
                  <a
                    className="btnCategory"
                    onClick={() => handleTypeChange(item.nombre)}
                  >
                    {item.nombre}
                  </a>
                  {item.nombre === "Musculacion" && (
                    <ul style={{paddingLeft:"6%"}}>
                      {subCategorias.map((subCategoria, index) => (
                        <li key={index}>
                          <a
                            className="btnSubCategory"
                            onClick={() => handleSubCategoryClick(item.nombre, subCategoria.nombre)} // Pasar tanto la categoría principal como la subcategoría
                          >
                            -{subCategoria.nombre} {/* Render the specific property */}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}

                </li>
              ))}
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CategoryButtons;
