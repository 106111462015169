import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/styles.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Inicio from "./Pages/Inicio";
import Ofertas from "./Pages/Ofertas";
import Categorias from "./Pages/Categorias";
import ProductDetail from "./Pages/ProductDetail";
import { CartProvider } from "./components/Cart/CartContext";
import Formulario from "./Pages/Formulario";
import Cart from "./Pages/Cart";
import Nosotros from "./Pages/Nosotros";
import MainSubCategoria from "./Pages/MainSubCategoria";


function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Inicio />} />
          <Route exact path="/Catalogo" element={<Categorias />} />
          <Route exact path="/Ofertas" element={<Ofertas />} />
          <Route exact path="/Nosotros" element={<Nosotros />} />
          <Route exact path="/Cart" element={<Cart />} />
          <Route exact path='/Catalogo/:products' element={<ProductDetail />} />
          <Route exact path="/Subcategorias" element={<MainSubCategoria />} />
          <Route exact path="/FormularioDeEnvio" element={<Formulario />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
