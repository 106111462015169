import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const FirebaseDataFetcher = ({ collectionName }) => {
    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {
        const querySnapshot = await getDocs(collection(db, collectionName));
        const newData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setData(newData);
    }, [collectionName]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return data;
};

export default FirebaseDataFetcher;
