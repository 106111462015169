import React from "react";
import "../css/styles.css";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import MainInicio from "../components/MainInicio";

const Inicio = () => {
  return (
    <div className="contMain">
      <Navbar />
      <MainInicio />
      <Footer />
    </div>
  );
};

export default Inicio;
