import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../css/outlet.css'
import '../css/styles.css'
import '../css/productos.css'

const Outlet = () => {
    return (
        <div>
            <Navbar />

            <section className='mainNosotros'>
                NOSOTROS
            </section>

            <Footer />
        </div>
    )
}

export default Outlet
