import React, { useState, useEffect, useCallback } from "react";
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";
import { useCart } from "../components/Cart/CartContext";

const MainSubCategoria = () => {
    const [subcategorias, setSubcategorias] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("Musculacion");
    
    const { cartSubCategoryData } = useCart();

    useEffect(() => {
        setSubcategorias(cartSubCategoryData);
    }, [cartSubCategoryData])

    const handleAddLS = useCallback((subcategoriaNombre) => {
        localStorage.setItem('selectedSubcategoriaNombre', subcategoriaNombre);
        localStorage.setItem('selectedCategoriaNombre', selectedCategory);
        window.location.href=`/Catalogo`
    },[selectedCategory]);

    return (
        <div className="contenedorGeneralMainSubCatego">
            <Navbar />
            {selectedCategory === "Musculacion" && (
                <div className="mainSubCateg">
                    <h1 className="tituloMainSubCateg">Categoría: {selectedCategory}</h1>
                    <div>
                        <h2 className="subtituloMainSubCateg">Subcategorías:</h2>
                        <div className="row containerImgSubCatego">
                            {subcategorias.map(subcategoria => (
                                <a
                                    key={subcategoria.id}
                                    onClick={() => handleAddLS(subcategoria.nombre.toString())}
                                    className="imgLinkSubCatego col-md-3 col-12"
                                >
                                    <div className="imgBoxSubCatego">
                                        <img className="imgSubCatego" src={subcategoria.imagen} alt="" />
                                        <p className="textMainSubCatego">
                                            {subcategoria.nombre}
                                        </p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <Footer />
        </div>
    );
};

export default MainSubCategoria;
