import React from "react";
import NavMenu from "./NavMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import "../../css/styles.css";


const Navbarr1 = ({
  menuOpen,
  toggleMenu,
  selectedType,
  handleTypeChange,
  handleFilterChange,
}) => {
  return (
    <div>
      <nav className="navContFilter">
        <ul style={{ listStyle: "none", paddingLeft: 0 }}>
          <li className="contFilter1">
            <button className="menu-toggle btnFiltro" onClick={toggleMenu} style={{ fontSize: "15px" }}>
              <span style={{ fontWeight: "600" }}><FontAwesomeIcon icon={faSliders} /> Filtrar</span>
            </button>
          </li>
          <li>
            <NavMenu
              selectedType={selectedType}
              handleTypeChange={handleTypeChange}
              handleFilterChange={handleFilterChange}
            />
          </li>
        </ul>
      </nav>

    </div>
  );
};



export default Navbarr1;
