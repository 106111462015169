import React, { useState, useEffect, useRef } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../components/Search/SearchBar';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import imgOfertas from '../assets/img/ofertas-e-fit.avif';
import { useCart } from '../components/Cart/CartContext';
import { Link } from 'react-router-dom';

const Ofertas = () => {
    const [selectedType, setSelectedType] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [data, setData] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [products, setProducts] = useState([]);


    const urlParams = new URLSearchParams(window.location.search);
    const locParam = urlParams.get("loc");

    const { cartProductData } = useCart();

    useEffect(() => {
        setProducts(cartProductData);
    }, [cartProductData]);

    const searchProducts = (term) => {
        const results = term
            ? data.filter((product) =>
                product.name.toLowerCase().includes(term.toLowerCase())
            )
            : data;
        setSearchResults(results);
    };

    useEffect(() => {
        if (dataLoaded) {
            searchProducts(searchTerm);
        }
    }, [dataLoaded, searchTerm]);

    useEffect(() => {
        if (dataLoaded) {
            searchProducts("");
        }
    }, [dataLoaded]);

    const handleSearchTermChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);
    };
    const toggleMenu = () => {
        setMenuOpen((menuOpen) => !menuOpen);
    };

    const handleTypeChange = (type) => {
        setSelectedType(type);
        if (menuOpen) {
            setMenuOpen(false);
        }
    };



    const menuRef = useRef(null);

    const handleOptionClickFl = () => {
        setSelectedFilter("Ofertas");
    };

    const handleBorrarSearch = () => {
        setSearchTerm("");
    };


    return (
        <div className='mainOfertas'>
            <Navbar />
            <div>
                <img className='flyerOfertas' src={imgOfertas} alt="imgOfertas" />
            </div>
            <div>
                {
                    <Offcanvas show={menuOpen} onHide={toggleMenu} placement="end">
                        {
                            <div className={`row cart-overlay`}>
                                <div className={`col-12 cart-container`}>
                                    <div ref={menuRef} className="contCart">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "right",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    width: "30px",
                                                    height: "20px",
                                                    position: "relative",
                                                    cursor: "pointer",
                                                    top: "5px",
                                                }}
                                                type="button"
                                                onClick={toggleMenu}
                                            >
                                                <FontAwesomeIcon icon={faClose} className="iconX" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                    </Offcanvas>
                }
                <div
                    className="contenedorSearchBar"
                    style={{ paddingRight: "0%", marginRight: "0%", width: "100%" }}
                >
                    <div>
                        <div
                            className="col-12 col-sm-12 col-lg-9 col-xl-12 contSearchBar"
                            style={{
                                paddingRight: "0%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <button
                                onClick={() => {
                                    handleTypeChange("Ofertas");
                                    handleOptionClickFl("Ofertas");
                                    handleBorrarSearch("");
                                }}
                                className="txtBorrarFil"
                            >
                                <h6>Borrar Filtros</h6>
                            </button>
                            <SearchBar
                                searchTerm={searchTerm}
                                handleSearchTermChange={handleSearchTermChange}
                            />

                        </div>
                        <h4 className='tresYSeisOferta'>
                            3 y 6 Cuotas sin Interés con Macro
                        </h4>
                    </div>
                </div>
                <div
                    className="row sectionContainerOferta"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <h2>
                        {selectedType}
                    </h2>
                    {products.filter((product) => product.oferta === "Si").map((product) => (
                    <div className="container-fluid col-md-4 col-12 cardProducto">
                        <Link to={`/Catalogo/${product.id}`} className="col-3 linkProductDetail">
                            <div className="card">
                                <div className="imageBoxOferta">
                                    {product.images.length > 1 ? (
                                        <img style={{
                                            width: "100%",
                                            height: "220px"
                                        }} src={product.images[1]} alt={product.name} />
                                    ) : (
                                        <img src={product.images} alt={product.name} />
                                    )}
                                    <div className="content">
                                        <h2>
                                            {product.name}
                                        </h2>
                                        <p>
                                            Precio: USD {product.precio} + IVA
                                        </p>
                                        <button className="verMasBtn">
                                            Ver Más
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    ))}
                </div>           
                <section className='loguitoWSP'>
                    <a className='iconoWspMain' href="https://api.whatsapp.com/send/?phone=5493572448243&text=Hola!+Te+quiero+hacer+una+consulta+sobre+tu+servicio...&type=phone_number&app_absent=0" target='blank'>
                        <FontAwesomeIcon className='wspLink' icon={faWhatsapp} />
                    </a>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Ofertas;
